/* Custom Container Mixin.
   =======================
*/

// override clearfix with pie-clearfix:
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @include has-layout;
}

@mixin coloredBox($color, $background) {
  background: $background;
  color: $color;
  border-radius: 0.1em;
  box-shadow: 0 0 0.1em 0 $colorBoxShadow;
  margin: 0.5em 0;
  padding: 0.6em;
}

@mixin darkColoredBox($color, $background) {
  @include coloredBox($color, $background);
  color: $colorBodyBg;
  a {
    color: $colorBodyBg;
    text-decoration: underline;
  }
  h2,
  h3,
  h4 {
    color: $colorBodyBg;
    &::before {
      background: $colorBodyBg;
    }
  }
  .subarticleMoreLink {
    &::before {
      color: $colorBodyBg;
    }
  }
  li:before {
    color: #ffffff !important;
  }
}

@mixin globalNavBG {
  // border-color: #dadada;
  // border-bottom-color: rgb(218, 218, 218);
  // border-radius: 2px 2px 2px 2px;
  // border-style: solid;
  // border-bottom-style: solid;
  // border-width: 1px;
  // border-bottom-width: 1px;
  // border-bottom: 0.22em solid #dadada;
  background-color: #fafafa;
  background-image: -ms-linear-gradient(top, #fafafa 0%, #eaeaea 100%);
  background-image: -moz-linear-gradient(top, #fafafa 0%, #eaeaea 100%);
  background-image: -o-linear-gradient(top, #fafafa 0%, #eaeaea 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fafafa),
    color-stop(1, #eaeaea)
  );
  background-image: -webkit-linear-gradient(top, #fafafa 0%, #eaeaea 100%);
  background-image: linear-gradient(to bottom, #fafafa 0%, #eaeaea 100%);
}
