
body.viewpermission-view #content-core,
body.viewpermission-jlu-ublibrarydb-readinternalfields #content-core,
body.viewpermission-none #content-core,
body.mce-content-body{
  .justfind-snippet{
    display: flex;
    flex-direction: row;
    background-color: #cddce9;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    .justfindSearchForm{
      flex-grow: 4;
      display: flex;
      flex-wrap: nowrap;
      gap: min(2em, 1vw);
      height: 3.5em;
      > a {
        display: contents;
        > img{
          flex-grow: 0;
          flex-shrink: 3;
          height: min(100%, 7vw);
          max-width: none;
          width: auto;
        }
      }
      [name="lookfor"]{
        flex-grow: 1;
        font-size: 1.5em;
        width: max(100%, 20ch);
      }
      > button {
        flex-grow: 1;
        padding-bottom: 0.2rem;
        .bi{
          color: white;
          font-size: 2em;
          line-height: 1em;
        }
      }
    }
    .langugage-switcher {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: 0;
      li::before {
        content: none;
      }
      img {
        width: 2.5em;
      }
    }
  }
}

@media (max-width: 575px) {
  body.viewpermission-view #content-core,
  body.viewpermission-jlu-ublibrarydb-readinternalfields #content-core,
  body.viewpermission-none #content-core,
  body.mce-content-body{
    .justfind-snippet{
      // background-color: #cddce9;
      // padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
      flex-direction: column;
      .justfindSearchForm{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        gap: min(2em, 1.2vw);
        height: auto;
        > a {
          img{
            max-height:none;
            max-width: 100%;
            width: 80%;
            margin: 0 auto;
          }

        }
        .form-control{
          width: auto;
        }
        [name="lookfor"]{
          flex-grow: 2;
          font-size: 1.5em;
        }
        .bi{
          color: white;
          font-size: 2em;
        }
      }
      .langugage-switcher{
        padding: 1em 0 0 0;
        flex-direction: row;
        gap: 2em;
        justify-content: center;
      }
    }
  }
}