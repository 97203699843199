// Grid //

// desktop
@media (min-width: 1200px) {
  body.viewpermission-view > .outer-wrapper,
  body.viewpermission-jlu-ublibrarydb-readinternalfields > .outer-wrapper,
  body.viewpermission-none > .outer-wrapper{
    display: grid;
    margin: 0 auto;
    max-width: 1200px;
    min-height: 98vh;
    column-gap: 0;
    row-gap: 3px;
    grid-template-columns: 239px 722px 237px;
    // grid-template-columns: 240px 705px 240px;
    grid-template-rows: 98px 54px fit-content(1em) fit-content(1em) 1fr 1.5em 80px;
  }
  body.col-content.col-one.col-two > .outer-wrapper{
    grid-template-areas:
      "logo banner sublogo"
      "sectionname globalnav searchbox"
      "portalmessages portalmessages portalmessages"
      "lockstatus lockstatus lockstatus"
      "colone content coltwo"
      "breadcrumb breadcrumb breadcrumb"
      "footer footer footer";
  }
  body.col-content.col-one > .outer-wrapper{
    grid-template-areas:
      "logo banner sublogo"
      "sectionname globalnav searchbox"
      "portalmessages portalmessages portalmessages"
      "lockstatus lockstatus lockstatus"
      "colone content content"
      "breadcrumb breadcrumb breadcrumb"
      "footer footer footer";
  }
  body.col-content.col-two .outer-wrapper{
    grid-template-areas:
      "logo banner sublogo"
      "sectionname globalnav searchbox"
      "portalmessages portalmessages portalmessages"
      "lockstatus lockstatus lockstatus"
      "content content coltwo"
      "breadcrumb breadcrumb breadcrumb"
      "footer footer footer";
  }
  body.col-content .outer-wrapper{
    grid-template-areas:
      "logo banner sublogo"
      "sectionname globalnav searchbox"
      "portalmessages portalmessages portalmessages"
      "lockstatus lockstatus lockstatus"
      "content content content"
      "breadcrumb breadcrumb breadcrumb"
      "footer footer footer";
  }

  body.viewpermission-view,
  body.viewpermission-jlu-ublibrarydb-readinternalfields,
  body.viewpermission-none {
    font-size: $font-size-base;
    .mobile{
      display: none;
    }
    #section-document-actions{
      display: block;
      ul li{
          &::before {
          content:none;
          }
        list-style-type: none;
      }
    }
    #dlinksWrapper.offcanvas-end {
      width: max(50vw, 600px);
    }
    .dlinkGroups{
      display: flex;
      flex-wrap: wrap;
      .dlinksGroup{
        // width: max(200px, 96vw);
        padding-right: 2em;
        &:last-child{
          padding-right: 0;
        }
      }
    }
    #dlinks {
      display: block;
      padding: 0.3em 0.3em 0 0;
      a{
        color: $colorTextDark;
      }
      svg{
        width: 1.4em;
        height: auto;
      }
    }
    .outer-wrapper > header {
      display: contents;
      #portal-logo {
        grid-area: logo;
        place-self: center;
      }
      #top-banner {
        grid-area: banner;
        place-self: center;
        min-height: 108px;
        background-size: cover;
        margin-left: - $grid_gap;
      }
      #sub-logo {
        grid-area: sublogo;
	margin: auto;
      }
      #section-name {
        grid-area: sectionname;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        padding: .6em 0.4em 0.73em 0.4em;
        line-height: var(--globalnav-line-height);
        font-size: var(--globalnav-font-size);
        a {
          text-decoration: none;
        }
        @include globalNavBG;
      }
      nav {
        grid-area: globalnav;
        font-size: 1em;
        @include globalNavBG;
        margin-left: - $grid_gap;
      }
      .searchbox-wrapper {
        grid-area: searchbox;
        @include globalNavBG;
        display: flex;
        align-items:flex-start;
        justify-content: space-between;
        padding: 0 0 0 $grid_gap;
       // border-left: 1px solid $white;
        border-right: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;

        #searchGadget {
          height: 30px;
          font-size: 0.8em;
          margin-top: 5px;
          padding: 0;
          min-width: 188px;
          text-indent: 0.5em;
        }
        #searchWrapper {
          flex-grow: 1;
        }
        #dlinksWrapper {
          flex-shrink: 1;
        }
        #portal-advanced-search {
          display: none;
        }
        .offcanvas {
          transition: none;
          transform: none;
          position: inherit;
          visibility: visible !important;
          background-color: transparent;
          .offcanvas-header {
            display: none;
          }
          .offcanvas-body {
            padding: 0;
          }
        }
        .offcanvas-end {
          border-left: 0;
          width: auto;
        }
      }
    }
    #global_statusmessage{
      grid-area: portalmessages;
    }
    #plone-lock-status{
      grid-area: lockstatus;
    }
    main {
      display: contents;
      #portal-column-content {
        grid-area: content;
        padding: 0;
      }
      #portal-column-one {
        grid-area: colone;
        padding-right: $grid_gap;
      }
      #portal-column-two {
        grid-area: coltwo;
        padding-left: $grid_gap;
      }
      #content > header {
        display: grid;
        column-gap: 0;
        row-gap: 3px;
        grid-template-columns: auto 150px;
        grid-template-rows: auto;
        grid-template-areas:
          "title documentactions"
          "abovetitle abovetitle"
          "lead lead"
          "belowtitle belowtitle"
          "headercontent headercontent";
        #viewlet-above-content-title {
          grid-area: documentactions;
          justify-self: end;
          ul {
            display: flex;
            > li {
              padding-left: 0.5em;
            }
          }
        }
        > h1 {
          grid-area: title;
        }
        #viewlet-below-content-title {
          grid-area: abovetitle;
        }
        .lead {
          grid-area: lead;
        }
        #viewlet-below-content-description {
          grid-area: belowtitle;
        }
      }
    }

    footer {
      display: block;
      grid-area: footer;
    }

    // reset some mobile settings:
    .portletNavigationTree {
      display: flex;
    }
  }
}
