// bugfix until new mockup version is released: 10.08.2022
// .modal-wrapper{
//   z-index: 1341 !important;
// }

body.viewpermission-view,
body.viewpermission-jlu-ublibrarydb-readinternalfields,
body.viewpermission-none {
  // .navTree a svg{
  //   display: none;
  // }
  p{ margin-bottom: 1em;}

  &.template-tabular_view{
    table {
      th.image, td.image{
        display: none;
      }
      th.type, td.type{
        display: none;
      }
    }
  }

  .contentmenuflags svg,
  .plone-icon-flag{
    width: auto;
    height: 0.9em;
  }

  #portal-column-one nav {
    padding: 0;
  }

  .portletNavigationTree .card-header {
    @include visually-hidden;
  }
  /* Hidden */
  .hiddenStructure {
    display: block;
    padding: 0 !important; /* important */
    margin: 0 !important; /* important */
    background: transparent !important;
    background-image: none; /* safari bug */
    border: none !important;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: -0.1em 0 0 -0.1em;
    width: 1px;
  }
}

.plone-loader{
  position: absolute;
  top: 50vh;
  left: 50vw;
  [role="status"]{
    display: none;
  }
}
