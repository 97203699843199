// Grid //

// tablet only
@media (min-width: 576px) and (max-width: 1200px) {
  .cpttableless {
    width: 98% !important;
    margin-bottom: 1em;
    display: block;
    div.viewlet {
      margin-left: 0 !important;
    }
  }
  .cptable,
  .cprow,
  .cpcell {
    display: block !important;
  }

  .navTreeCurrentItem{
    font-weight: bold;
  }

}

// tablet and above
@media (min-width: 576px) {
  body.viewpermission-view,
  body.viewpermission-jlu-ublibrarydb-readinternalfields,
  body.viewpermission-none {
    font-size: $font-size-base;
    #top-banner {
      display: block;
    }

    #sub-logo {
      display: block;
    }
  }

  body.viewpermission-view > .outer-wrapper,
  body.viewpermission-jlu-ublibrarydb-readinternalfields > .outer-wrapper,
  body.viewpermission-none > .outer-wrapper {
    display: grid;
    margin: 0 auto;
    min-height: 98vh;
    column-gap: $grid_gap;
    row-gap: $grid_gap;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: (60px - $grid_gap) (70px - $grid_gap) (80px - $grid_gap) fit-content(1em) fit-content(1em) 1fr;
  }
  body > .outer-wrapper {
    grid-template-areas:
      "logo mobileactions"
      "sectionname sectionname"
      "globalnav globalnav"
      "portalmessages portalmessages"
      "lockstatus lockstatus"
      "content content"
      "colone colone"
      "coltwo coltwo"
      "breadcrumb breadcrumb"
      "footer footer";
  }
  body.viewpermission-view,
  body.viewpermission-jlu-ublibrarydb-readinternalfields,
  body.viewpermission-none {
    .outer-wrapper > header {
      display: contents;
      #portal-logo {
        grid-area: logo;
        height: 60px;
      }
      #section-name {
        grid-area: sectionname;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 0.6em;
        @include globalNavBG;
        border-right: 1px solid $white;
        border-left: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
      }
      nav {
        grid-area: globalnav;
        font-size: 1em;
        // height: 80px;
        @include globalNavBG;
      }
      .mobileActions{
        grid-area: mobileactions;
      }
    }
    #global_statusmessage{
      grid-area: portalmessages;
    }
    #plone-lock-status{
      grid-area: lockstatus;
    }
    main {
      display: contents;
      #portal-column-content {
        grid-area: content;
      }
      #portal-column-one {
        grid-area: colone;
        padding: 0;
      }
      #portal-column-two {
        grid-area: coltwo;
      }
    }
    footer {
      display: contents;
      #portal-breadcrumbs {
        grid-area: footer;
      }
    }
  }
}
