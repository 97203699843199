// only non-content styles here please!
// use _jlu_content.scss for content related styles!

#form-widgets-smailnr, #searchform > div.d-flex.mb-2 > div.nav-item {
  display: none;
}


body.template-view #formfield-form-widgets-smailnr > label {
  display: none;
}
.template-edit [name="caption"]:disabled {
  opacity: 0.1;
}

.template-login #portal-column-content {
  .login-wrapper {
    display: flex;
    flex-direction: column;
    .card {
      align-self: center;
    }
  }
}
.frontend.site-de.section-startseite-ub,
.frontend.site-en.section-startseite-ub,
.frontend.site-de.section-index,
.frontend.site-en.section-index {
  h1,
  #viewlet-above-content-title {
    left: -2000px;
    line-height: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
  }
}
.frontend.site-de.section-index,
.frontend.site-en.section-index {
  .documentActions {
    ul {
      list-style-type: none;
      li {
        display: inline-block;
        margin-right: 0.6em;
        vertical-align: middle;
        a {
          color: transparent;
          float: left;
          height: 14px;
          width: 14px;
          overflow: hidden;
          text-indent: -999px;
        }
        &::before {
          display: none;
        }
      }
    }
  }
  #jluindexrs {
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    box-shadow: 3px 3px 10px #dadada;
    padding: 16px;
    .indexTitle {
      font-size: 0.9rem;
    }
  }
  #jluindexrs ul {
    padding-left: 0;
    list-style-type: none;
    li::before {
      display: none;
    }
  }
  .lang-icon {
    height: 20px;
    width: auto;
  }
}

body.viewpermission-view,
body.viewpermission-jlu-ublibrarydb-readinternalfields,
body.viewpermission-none,
body.mce-content-body {
  color: $colorText;
  background-color: #ffffff;
  text-align: left;
  line-height: 1.4em;

  .viewlet-document-actions {
    .plone-icon {
      width: auto;
      height: 0.9em;
    }
    #document-action-print{
      font-size: 0.9rem;
      padding-left: .45em !important;
    }
    #document-action-uid{
      font-size: 1.1rem;
      padding-left: .4em !important;
    }
    ul{
      margin: 0 !important;
    }
  }

  li.nav-item::before {
    content: none !important;
  }

  #section-related {
    header {
      font-size: larger;
      border-bottom: 1px solid $gray-400;
      padding: 0.1em 0;
      margin-bottom: 0.4em;
    }
    small {
      display: none;
    }
  }

  .visualBorder img,
  img.visualBorder {
    border: 1px solid $gray;
    border-radius: 2px;
    box-shadow: 3px 3px 10px $gray;
    padding: 6px;
  }

  #section-byline {
    color: $colorDiscreet;
  }

  a,
  a:visited,
  a:link {
    // color: $colorLink;
    img {
      border: 0;
    }
    text-decoration: none;
  }

  a[class$="_intern"]:before,
  li[class$="_intern"] > a:before {
    background-color: #bc031c;
    color: #ffffff;
    content: "intern";
    padding-left: 2px;
    padding-right: 2px;
    font-size: 94%;
    margin-right: 2px;
  }

  .noborder {
    border: none;
  }

  /* Spam Schutz */
  .jluext:after {
    content: "@" attr(rel);
  }
  .jluint:after {
    content: "@" attr(rel) "-giessen.de";
  }

  .mejs-container {
    margin: 0.4em;
  }

  /* Headings. */
  h1 {
    // font-size: 1.7em;
    letter-spacing: -0.02em;
    color: $colorTextDark;
    margin-bottom: 0;
    &::before {
      background: none repeat scroll 0 0 $blue;
      content: "";
      display: inline-block;
      left: 0;
      position: relative;
      top: 1px;
      height: 0.92em;
      margin: 0 8px 0 0;
      width: 0.92em;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $colorTextDark;
    margin-top: 0.6em;
    &::before {
      background: none repeat scroll 0 0 $blue;
      content: "";
      display: inline-block;
      left: 0;
      position: relative;
      top: 1px;
      height: 0.92em;
      margin: 0 8px 0 0;
      width: 0.92em;
    }
  }

  .nobox {
    &::before {
      display: none;
    }
  }
  .text-blue {
    color: $blue;
  }

  #parent-fieldname-title {
    &::before {
      margin: 0px;
    }
  }

  blockquote {
    padding-left: 0.5em;
    margin-left: 0;
    border-left: 4px solid $colorBorder;
    color: $colorDiscreet;
  }

  .subarticleMoreLink,
  .teaserBlockMoreLink {
    margin-left: 1.2em;
    display: block;
    &::before {
      content: "\279C";
      float: left;
      margin-left: -1.2em;
      max-height: 0;
      max-width: 0; // for IE //
      color: $blue;
    }
    span {
      padding-left: 1.2em;
    }
  }

  .TextMoreLink {
    &::before {
      content: "\279C ";
      max-height: 0;
      padding-right: 0.2em;
      max-width: 0; // for IE //
      color: $blue;
    }
  }

  .tileFooter a {
    &::before {
      content: "\279C";
      display: inline;
      max-height: 0;
      position: relative;
      color: $blue;
    }
    .hiddenStructure {
      float: left;
    }
  }

  .tileImage {
    float: right;
  }

  /* @group  Quick Links */
  #dlinks {
    .jluExtOverlay {
      color: $colorTextDark;
      font-weight: 900;
      font-size: 1.1em;
      // height: 18px;
      // width: 18px;
    }
  }
  /* @end */

  /* @group Listings */
  a.link-anchor {
    border: none !important;
    color: Black !important;
    cursor: default !important;
  }

  pre {
    background-color: #dee7ec;
    border: 1px solid #8cacbb;
    color: Black;
    font-family: Monaco, "Courier New", Courier, monospace;
    font-size: 100%;
    overflow: auto;
    padding: 1em;
  }
  .visualHighlight {
    background-color: #ffffbb;
  }

  /* for greying out inherited roles */
  .noInheritedRoles {
    color: #a0a0a0;
  }

  #content,
  #cpContent {
    .lead,
    .documentDescription,
    #description {
      color: $colorTextDark;
      font-size: 1em;
      font-weight: bold;
      margin-top: 0.6em;
    }
  }

  .gelb {
    background: #fbf594;
    color: #000;
    border-radius: 0.1em;
    box-shadow: 0 0 0.1em 0 #888;
    margin: 0.5em 0;
    padding: 0.6em;
  }

  .blueborder {
    background: #fff;
    color: #000;
    border-radius: 0.1em;
    box-shadow: 0 0 0.1em 0 #888;
    margin: 0.5em 0;
    padding: 0.6em;
    border: 1px solid #8cacbb;
  }

  #logo {
    a {
      display: block;
      height: 6em;
      width: 8em;
      margin-left: 2px;
      margin-top: 4px;
      img {
        width: 100%;
      }
    }
  }

  header nav {
    display: flex;
    #portal-globalnav {
      display: flex;
      align-items: stretch;
      flex-grow: 1;
      height: 100%;
      border-right: 1px solid $white;
      border-left: 1px solid #d1d1d1;
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
    }
    #portal-globalnav li {
      flex-grow: 1;
      display: flex;
      align-items: stretch;
      width: 100%;
      border-left: 1px solid $white;
      border-right: 1px solid $grayLight;
      text-align: center;
      text-decoration: none;
      list-style-type: none;
      a {
        flex-grow: 1;
        border: none;
        color: $colorText;
        overflow: hidden;
        padding: 0.6em 0.4em 0 0.4em;

        text-decoration: none;
        font-size: var(--globalnav-font-size);
        line-height: var(--globalnav-line-height);
      }
    }
    #portal-globalnav li.inPath a,
    #portal-globalnav li:hover a {
      background-color: $blue;
      color: $white;
    }
  }
  label .required::after,
  label.required::after {
    color: #dc3545;
    content: "•";
    font-size: 1.25rem;
    line-height: 1;
  }

  footer ul {
    list-style-type: none;
    li {
      &::before {
        display: none;
      }
    }
  }
}

#offcanvasDlinksLabel::before{
  content:none;
  height: 0;
  width: 0;
  margin: 0;
}
