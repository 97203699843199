body.viewpermission-view,
body.viewpermission-jlu-ublibrarydb-readinternalfields,
body.viewpermission-none {
  .dragindicator {
    outline: 2px solid #ffd700;
  }
  #foldercontents-order-column {
    padding: 0;
  }
  div.listingBar {
    font-size: 80%;
    margin: 1em 0;
  }
  div.listingBar .next {
    display: inline-block;
    float: right;
  }
  div.listingBar .previous {
    display: inline-block;
  }
  div.listingBar a {
    display: inline-block;
    padding: 0.26em 0.6em;
  }
  /* @end */
}
